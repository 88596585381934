import merge from 'lodash/merge'
import { isTarget } from '../utills/Common'

const linkConfigs = {
  // dev is the default development environment set by .env.development and the default pipeline in .vela.yml
  dev: {
    vmm: {
      site: 'https://vmmdev.partnersonline.com/vmm',
      redirect: 'VM2Redirect.jsp?buspartnerId=',
    },
    mvs: 'https://mvslcmdev.perf.partnersonline.com/lcm/',
    pol: 'https://xyc.partnersonline.com/',
  },
  stg: {
    vmm: {
      site: 'https://vmmdev.partnersonline.com/vmm/',
      redirect: 'VM2Redirect.jsp?buspartnerId=',
    },
    mvs: 'https://mvslcm.perf.partnersonline.com/lcm/',
    pol: 'https://xyc.partnersonline.com/lcm/',
  },
  prod: {
    vmm: {
      site: 'https://vmm.partnersonline.com/vmm/',
      redirect: 'VM2Redirect.jsp?buspartnerId=',
    },
    mvs: 'https://mvslcm.partnersonline.com/lcm/',
    pol: 'https://www.partnersonline.com/',
  },
}

export const internalLinkConfigs = {
  dev: {
    vmm: {
      site: 'https://xycvmm-tct.target.com/vmm/',
      redirect: 'VM2Redirect.jsp?buspartnerId=',
    },
    mvs: 'https://mvs.dev.target.com/lcm/internal/',
  },
  stg: {
    vmm: {
      site: 'https://xycvmm-tct.target.com/vmm/',
      redirect: 'VM2Redirect.jsp?buspartnerId=',
    },
    mvs: 'https://mvs-stage.dev.target.com/lcm/internal/',
  },
  prod: {
    vmm: {
      site: 'https://vmm.target.com/vmm/',
      redirect: 'VM2Redirect.jsp?buspartnerId=',
    },
    mvs: 'https://mvs.prod.target.com/lcm/internal/',
  },
}
const appEnv = process.env.REACT_APP_ENV
const config = linkConfigs[appEnv]
export const internalConfig = internalLinkConfigs[appEnv]
let linkConfig = config

if (isTarget()) {
  linkConfig = merge(linkConfig, internalLinkConfigs[appEnv])
}

export default linkConfig
